import React from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup } from "clutch/src/Button/Button";

import { readState } from "@/__main__/app-state.mjs";
import HeroesLinksList from "@/game-deadlock/components/HeroesLinksList.jsx";
import HeroPageHeader from "@/game-deadlock/components/HeroPageHeader.jsx";
import ItemImage from "@/game-deadlock/components/ItemImage.jsx";
import {
  ITEM_CATEGORIES,
  ITEM_CATEGORY_ENUM_TO_PARAMKEY,
  ITEM_TIER_VAL,
  ITEM_TIERS,
} from "@/game-deadlock/constants/items.mjs";
import { TableContainer } from "@/game-deadlock/HeroItems.style.jsx";
import { Content } from "@/game-deadlock/HeroOverview.style.jsx";
import { getItemColor } from "@/game-deadlock/utils/get-item-color.mjs";
import { getHeroIdFromName } from "@/game-deadlock/utils/getHeroIdFromName.mjs";
import { localeString } from "@/game-deadlock/utils/localestring.mjs";
import { useHeroItemsStats } from "@/game-deadlock/utils/use-hero-items-stats.mjs";
import { useHeroStats } from "@/game-deadlock/utils/use-hero-stats.mjs";
import { useHeroesList } from "@/game-deadlock/utils/use-heroes-list.mjs";
import { useItemsAbilities } from "@/game-deadlock/utils/use-items-abilties.mjs";
import DataTable, { DataTableLoading } from "@/shared/DataTable.jsx";
import PageContainer from "@/shared/PageContainer.jsx";
import { useQuery, useRoute } from "@/util/router-hooks.mjs";

function HeroItems() {
  const { t } = useTranslation();
  const {
    parameters: [heroIdOrName],
  } = useRoute();

  const [tier, setTier] = useQuery<string>("tier", "");
  const [category, setCategory] = useQuery<string>("category", "");

  const {
    loading: _itemsLoading,
    dict: itemsStaticData,
    names: itemsStaticNames,
  } = useItemsAbilities();
  const { loading: _heroesListLoading, list: _heroesList } = useHeroesList();
  const {
    loading: _heroStatsLoading,
    stats: heroStats,
    heroId,
    heroName,
  } = useHeroStats(heroIdOrName);
  const { loading: heroItemStatsLoading, stats: heroItemStats } =
    useHeroItemsStats(heroIdOrName);

  const filteredItems = heroItemStats.filter((item) => {
    const itemStaticData = itemsStaticData[item.item_id];

    const itemCategory =
      ITEM_CATEGORY_ENUM_TO_PARAMKEY[itemStaticData?.m_eItemSlotType];
    const itemTier = ITEM_TIER_VAL[itemStaticData?.m_iItemTier];

    const matchesTier = tier ? `${itemTier}` === tier : true;
    const matchesCategory = category ? itemCategory === category : true;

    return matchesTier && matchesCategory;
  });

  return (
    <PageContainer>
      <HeroPageHeader
        heroId={heroId}
        heroName={heroName}
        stats={heroStats}
        titleSuffix={t("", "Items stats by tier and category")}
      />
      <section className={Content()}>
        <div className="main-col">
          <div
            className="filters"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "var(--sp-3)",
            }}
          >
            <span className="type-caption--semi shade2">
              {t("tft:filterBy", "Filter By")}
              {": "}
            </span>
            <ButtonGroup>
              {ITEM_CATEGORIES.map((itemCategory) => {
                const Icon = itemCategory.icon;
                return (
                  <Button
                    key={itemCategory.key}
                    className="button"
                    active={category === itemCategory.paramKey}
                    onClick={() => {
                      if (category === itemCategory.paramKey) {
                        setCategory("");
                      } else {
                        setCategory(itemCategory.paramKey);
                      }
                    }}
                    iconLeft={<Icon />}
                  >
                    {t(...itemCategory.label)}
                  </Button>
                );
              })}
            </ButtonGroup>
            <ButtonGroup>
              {ITEM_TIERS.map((itemTier) => {
                return (
                  <Button
                    key={itemTier.key}
                    className="button"
                    active={tier === itemTier.paramKey}
                    onClick={() => {
                      if (tier === itemTier.paramKey) {
                        setTier("");
                      } else {
                        setTier(itemTier.paramKey);
                      }
                    }}
                  >
                    {t(...itemTier.label)}
                  </Button>
                );
              })}
            </ButtonGroup>
          </div>
          {heroItemStatsLoading ? (
            <DataTableLoading tallRows loadingRows={20} />
          ) : (
            <DataTable
              tallRows
              indexCol
              sortCol={2} // pick rate
              className={TableContainer()}
              cols={[
                {
                  align: "left",
                  display: t("common:item", "Item"),
                  className: "item-meta",
                },
                {
                  display: t("common:stats.winRate", "Win Rate"),
                  className: "winrate",
                  isStat: true,
                },
                {
                  display: t("common:stats.pickRate", "Pick Rate"),
                  className: "pickrate",
                  isStat: true,
                },
                {
                  display: t("deadlock:stats.tierPickRate", "Tier Pick Rate"),
                  className: "tier-pickrate",
                  isStat: true,
                  tooltip: t(
                    "deadlock:common.tierPickRateTooltip",
                    "Pick rate within the item's given Tier (I/II/III/IV)",
                  ),
                },
                {
                  display: t("common:stats.matches", "Matches"),
                  className: "matches",
                  isStat: true,
                },
              ]}
              rows={filteredItems.map((item) => {
                const itemStaticData = itemsStaticData[item.item_id];
                return [
                  {
                    display: (
                      <div
                        className="item-meta"
                        style={{
                          "--wep-category-color": getItemColor(itemStaticData),
                        }}
                      >
                        <ItemImage
                          key={item.item_id}
                          itemId={item.item_id}
                          size={28}
                          withRoman
                          className="item-img"
                        />
                        <span className="item-name type-caption--bold">
                          {itemsStaticNames[item.item_id] || ""}
                        </span>
                      </div>
                    ),
                    value: item.item_id,
                  },
                  {
                    display: localeString(item.win_rate, true, 1),
                    value: item.win_rate,
                  },
                  {
                    display: localeString(item.item_pick_rate, true, 1),
                    value: item.item_pick_rate,
                  },
                  {
                    display: localeString(item.item_tier_pick_rate, true, 1),
                    value: item.item_tier_pick_rate,
                  },
                  {
                    display: localeString(item.num_matches),
                    value: item.num_matches,
                  },
                ];
              })}
            />
          )}
        </div>
        <aside className="side-col">
          <HeroesLinksList heroIdOrName={heroIdOrName} tab="items" />
        </aside>
      </section>
    </PageContainer>
  );
}

export default HeroItems;

export function meta([heroIdOrName]: [string]) {
  const heroId = getHeroIdFromName(heroIdOrName, readState.deadlock.heroes);
  const hero = readState.deadlock.heroes[heroId];
  const name = hero?.heroName;
  return {
    title: name
      ? [
          "deadlock:meta.heroPage.title",
          "{{name}}'s Deadlock Win Rate, Pick Rate, and tier list – Blitz Deadlock",
          { name },
        ]
      : [
          "deadlock:meta.tierlist.title",
          "Deadlock Tier list, Win Rates, Pick Rates, and more – Blitz Deadlock",
        ],
    description: [],
    subtitle: name
      ? [
          "deadlock:meta.heroNameSubtitle",
          "Deadlock {{name}} Abilities Stats, Item builds, Matchups, Counters, Synergies, and more for Valve's new Shooter MOBA.",
          { name },
        ]
      : [
          "deadlock:meta.heroSubtitle",
          "Deadlock Hero Abilities Stats, Item builds, Matchups, Counters, Synergies, and more for Valve's new Shooter MOBA.",
        ],
  };
}
